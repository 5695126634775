<template>
  <v-form ref="formcard">
        <div>
          <label>نوع التوقيع</label>
          <div>
            <b-form-select style="width:200px;" class="selborder" v-model="sign_type" :options="signTypes" />
          </div>
        </div>
        <div style="background: #FFF;border:1px solid #ccc" class="ma-3 pa-1 text-center">
          <vue-drawing-canvass
            ref="VueCanvasDrawingd"
            :image.sync="image1"
            :width="400"
            :height="300"
            :stroke-type="strokeType1"
            :line-cap="lineCap1"
            :line-join="lineJoin1"
            :fill-shape="fillShape1"
            :eraser="eraser1"
            :lineWidth="line1"
            :color="color1"
            :background-color="backgroundColor1"
            :background-image="backgroundImage1"
            :watermark="watermark1"
            :initial-image="initialImage1"
            saveAs="png"
            :styles="{
              border: 'solid 1px #000',
            }"
            :lock="disabled1"
            @mousemove.native="getCoordinate1($event)"
            :additional-images="additionalImages1"
          />
        </div>
        <div style="text-align:center;padding:5px;margin:5px;">
            <v-btn style="background-color: blue;color:#fff !important" class="m-2" @click.prevent="$refs.VueCanvasDrawingd.reset()">{{lang.reset}}</v-btn>
            <v-btn style="background-color: red;color:#fff !important" class="m-2" @click.prevent="$refs.VueCanvasDrawingd.undo()">{{lang.undo}}</v-btn>
            <v-btn style="background-color: green;color:#fff !important" class="m-2" @click.prevent="save()">{{lang.save}}</v-btn>
            <v-btn style="background-color: black;color:#fff !important" class="m-2" @click.prevent="goBack()">{{lang.back}}</v-btn>
        </div>
        <p>
        X-axis: <strong>{{ x1 }}</strong
        >, Y-axis: <strong>{{ y1 }}</strong>
      </p>
      
    </v-form>
</template> 


<script  type="module">
// import addCustomer from '../components/addCustomer.vue'
// import addCar from '../components/addCar.vue'
// import {SnotifyPosition} from 'vue-snotify';
import axios from 'axios'
import VueDrawingCanvas from "vue-drawing-canvas";
export default {
  name: "signIt",
  components:{
    "vue-drawing-canvass": VueDrawingCanvas,
  },
  setup: () => {
    //
  },
  data: () => ({
    sign_type: 1,
    cardid: 0,
    initialImage1: [
        {
          type: "dash",
          from: {
            x: 262,
            y: 154,
          },
          coordinates: [],
          color: "#000000",
          width: 5,
          fill: false,
        },
      ],
      x1: 0,
      y1: 0,
      image1: "",
      eraser1: false,
      disabled1: false,
      fillShape1: false,
      line1: 2,
      color1: "#000000",
      strokeType1: "dash",
      lineCap1: "square",
      lineJoin1: "miter",
      backgroundColor1: "#FFFFFF",
      backgroundImage1: null,
      watermark1: null,
      additionalImages1: [],
  }),
  created() {
    this.cardid = this.$route.params.id;
    // // console.log(this.$route.params)
  },
  computed:{
    lang: function(){
        if(this.$cookies.get(this.$store.state.LangCooki) != null){
            if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
            return this.$store.state.lang.en;
            }else{
            return this.$store.state.lang.ar;
            }
        }
        else if(this.$store.state.deflang == 'en'){
            return this.$store.state.lang.en;
        }else{
            return this.$store.state.lang.ar;
        }
    },
    signTypes(){
      return [
        {text: 'توقيع الدخول', value: 1},
        {text: 'توقيع الخروج', value: 2},
      ]
    }
  },
  mounted() {
      if ("vue-drawing-canvaas" in window.localStorage) {
          this.initialImage1 = JSON.parse(
              window.localStorage.getItem("vue-drawing-canvaas")
          );
      }
  },
  methods:{
    async save(){
      const post = new FormData();
      post.append('defBranch',localStorage.getItem('defBranch'));
      post.append('financeYear',localStorage.getItem('financeYear'));
      post.append("type" , "addSing"); 
      post.append('auth',this.$cookies.get(this.$COOKIEPhase));
      post.append('data[id]',this.$route.params.id);
      post.append('data[image]',this.image1);
      post.append('data[sign_type]',this.sign_type)
      const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post)
      if(response && response.data){
        // console.log(response.data)
        // this.$router.push({path: '/home'})
        window.open('../api/print.php?cardid='+this.cardid,"_self");
      }
    },
    async setImage(event) {
      let URL = window.URL;
      this.backgroundImage1 = URL.createObjectURL(event.target.files[0]);
      await this.$refs.VueCanvasDrawingd.redraw();
    },
    async setWatermarkImage(event) {
      let URL = window.URL;
      this.watermark1 = {
        type: "Image",
        source: URL.createObjectURL(event.target.files[0]),
        x: 0,
        y: 0,
        imageStyle: {
          width: 600,
          height: 400,
        },
      };
      await this.$refs.VueCanvasDrawingd.redraw();
    },
    getCoordinate1(event) {
      let coordinates = this.$refs.VueCanvasDrawingd.getCoordinates(event);
      this.x1 = coordinates.x;
      this.y1 = coordinates.y;
    },
    getStrokes() {
      window.localStorage.setItem(
        "vue-drawing-canvaas",
        JSON.stringify(this.$refs.VueCanvasDrawingd.getAllStrokes())
      );
      alert(
        "Strokes saved, reload your browser to see the canvas with previously saved image"
      );
    },
    removeSavedStrokes() {
      window.localStorage.removeItem("vue-drawing-canvaas");
      alert("Strokes cleared from local storage");
    },
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.button-container {
  display: flex;
  flex-direction: row;
}
.button-container > * {
  margin-top: 5px;
  margin-right: 10px;
}
h6{
  font-weight: bolder;
}
.v-input--selection-controls {
    margin-top: 2px !important;
    padding-top: 2px !important;
}
.tobbuttom{
  display:flex;
  flex-direction: row;
  justify-content:center;
  justify-items: center;
  align-items: center;
}
.borderRad_left{
  border-top-left-radius: 5px 5px !important;
  border-bottom-left-radius: 5px 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding:5px 10px !important;
  font-size: .8rem  !important;
  margin-inline-start: 5px !important;
}
.borderRad_right{
  border-top-left-radius: 0 0 !important;
  border-bottom-left-radius: 0 0 !important;
  border-top-right-radius: 5px 5px !important;
  border-bottom-right-radius: 5px 5px !important;
  padding:5px 10px !important;
  font-size: .8rem  !important;
  margin-inline-end: 5px  !important;
}
.borderRad_none{
  border-top-left-radius: 0 0 !important;
  border-bottom-left-radius: 0 0 !important;
  border-top-right-radius: 0 0 !important;
  border-bottom-right-radius: 0 0 !important;
  padding:5px 10px !important;
  font-size: .8rem  !important;
}
.hideme{
  display:none !important;
}
.dispFlex{
  display:flex;
  justify-content: center !important;
}
.weightColor{
  background:#fff !important;
  color:#000 !important;
}
.redColor{
  background:red !important;
  color:#fff !important;
}
</style>